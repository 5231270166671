<template>
  <div class="text-right">
    <div>
      <div style="margin: 0.5rem 0 0.24rem 0;display: flex;align-items: center;">
        <!-- <div class="dian"></div> -->
        <div class="Description"><span
            style="color:#FF153C">*</span>{{ $t('slide1.mine_admin_problem_description_tips') }}</div>
      </div>
      <van-field class="message" v-model="message" rows="4" autosize type="textarea" maxlength="200"
        :placeholder="$t('slide1.textareaplaceholders')" show-word-limit />
    </div>
    <div>
      <div style="margin:0.4rem 0 0.24rem 0;display: flex;align-items: center;">
        <!-- <div class="dian"></div> -->
        <div class="Description">{{ $t('slide1.mine_admin_problem_proble_screenshot') }}</div>
      </div>
      <!-- <van-button class="upload" icon="plus" type="primary"></van-button> -->
      <!-- <van-uploader :max-count="9" :max-size="5 * 1024" v-model="fileList" :after-read="afterRead"
        :before-delete='deletes'>
        <van-icon class="upload" size="20" name="plus" />
      </van-uploader> -->
      <!-- <div style="display: flex;flex-wrap: wrap;">
        <div class="uploader__preview" v-for="(item, index) in image" :key="index">
          <div class="uploader__preview-image">
            <img class="image__loading" :src="getObjectURL(item)" alt="">
          </div>
          <div class="uploader__preview-delete" @click='deletes(index)'>
            <i class="van-icon van-icon-cross van-uploader__preview-delete-icon">
            </i>
          </div>
        </div>
        <div style="position: relative;display: inline-block;" v-if="len < 3">
          <van-icon class="upload" size="20" name="plus" />
          <input id="id" accept="image/jpeg,image/jpg,image/png" type="file" @change='fsubmit' ref="inputer"
            class="uploader__input">
        </div>
      </div> -->


      <van-uploader :after-read="afterRead" v-model="fileList" multiple :max-count="2" />
    </div>
    <div style="margin-bottom:2rem">
      <div style="margin: 0.4rem 0 0.24rem 0;display: flex;align-items: center;">
        <!-- <div class="dian"></div> -->
        <div class="Description">{{ $t('slide1.mine_admin_problem_contact_number') }}</div>
      </div>
      <van-field type="number" class="message" v-model="contact_number" :placeholder="$t('slide1.inputplaceholders')" />
    </div>

    <div v-show="isButtonShow" class="confirmBox">
      <div v-if="btnStatus" class="Confirm" :style="message == '' ? '' : 'opacity:1'" @click="shangchuan">{{
          $t('slide1.confirm')
      }}</div>
      <div v-else class="Confirm">
        <van-loading type="spinner" />
      </div>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import $ from 'jquery'
import { Toast } from "vant";
import Cookies from 'js-cookie';
import { join } from "path";
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userinfodata: null,
      mine_admin: [],
      details: '',
      message: '',
      contact_number: '',
      image: [],
      fileList: [],
      imageName: [],
      datalist: [],
      len: null,
      baseURL: '',
      btnStatus: true,
      isButtonShow: true, //默认按钮展示
      originHeight: '',
    };

  },
  created() {
    let payment_url = location.hash
    // let payment_url ='#/?langType=zh_CN&userName=%E4%BD%A0%E8%AF%B4%E8%AE%BE&userId=31045'
    // let payment_url = " #/?langType=zh_CN&userName=路&userId=2523&orderId=370&router=2&type= 1"
    let askIndex = payment_url.indexOf('?');
    var newStr = payment_url.slice(askIndex);
    var theRequest = new Object();
    if (newStr.indexOf('?') != -1) {
      var str = newStr.substr(1);
      let strs = str.split('&');
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
      }
    }
    this.datalist = theRequest;
      let langs = theRequest.langType.substring(0, 2)
      let lang = theRequest.langType
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else {
        this.$i18n.locale = 'en'
      }
    document.title = this.$t('slide1.feedback')
    document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    document.getElementsByTagName("html")[0].style.backgroundColor = "#F5F6F8";
    document.getElementsByTagName("body")[0].style.backgroundColor = "#F5F6F8";
    document.getElementsByTagName("html")[0].style.margin = "0 16px 0 16px";
    this.originHeight = document.documentElement.clientHeight || document.body.clientHeight;
    window.addEventListener('resize', this.resizeWindow)
    this.baseURL = axios.defaults.baseURL
    if (this.baseURL == 'https://gl.koudailive.com/') {
      this.baseURL = 'http://admin.api.koudailive.com/';
    } else {
      this.baseURL = 'http://admin.api.pocketliveapp.com/'
    }

  },
  mounted() {
  },
  methods: {
    shangchuan() {
      this.confirms()
    },
    afterRead(file) {
      console.log(file);
      // let that = this
      // console.log(file, that.fileList);
      // let photos = []
      // that.fileList.forEach(v => {
      //   let o = {
      //     base64Str: v.content,
      //   }
      //   photos.push(o)
      // })

      // photos = JSON.stringify(photos)

      // axios({
      //   url: that.baseURL + "/common/upload/base",
      //   type: "POST",
      //   dataType: 'json',
      //   data: photos,
      //   processData: false,  // 告诉jQuery不要去处理发送的数据
      //   contentType: false,   // 告诉jQuery不要去设置Content-Type请求头
      //   success: function (response, status, xhr) {
      //     console.log(response.code);
      //     if (response.code == 0) {
      //       that.confirms()
      //     }
      //   }
      // });
    },

    confirms() {
      let that = this;
      if (that.message == '' || that.fileList.length == 0) {
        that.message == '' ? Toast(this.$t('slide1.tips')) : Toast(this.$t('slide1.mine_admin_problem_proble_screenshot'))
      }
      else {
        that.btnStatus = false;
        let photos = []
        that.fileList.forEach(v => {
          photos.push(v.content)
        })
        let paramObj = {
          uid: this.datalist.userId,
          orderNum: this.datalist.orderId,
          content: that.message,
          contact: that.contact_number,
          imgs: photos,
        };
        paramObj = JSON.stringify(paramObj)
        axios({
          url: this.baseURL + 'user/complain',
          method: 'post',
          data: paramObj, //我们传formdata会占用整个传参对象
          headers: { "Content-Type": "application/json;charset=UTF-8" }
        }).then(response => {
          if (response.data.code == 200) {
            Toast(this.$t('slide1.confirmsToasty'))
            this.message = '',
              this.contact_number = '',
              this.fileList = [],
              this.btnStatus = true;
            // setTimeout(()=>{
            //   this.go()
            // },5000) 
          } else {
            this.btnStatus = true;
          }
        })
      }
    },

    // go(){
    //   //线上地址:"https://h5game.pocketliveapp.com/webpage/#/withdrawalInstructions"
    //   //测试地址
    //   if(this.$route.query.router == 2){
    //     window.location.href = "https://h5game.koudailive.com/webpage/#/withdrawalInstructions"
    //   }

    // },

    resizeWindow() {
      var u = navigator.userAgent;
      console.log(u);
      //判断是否运行在安卓系统
      if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
        var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //判断可视高度
        if (resizeHeight < this.originHeight) {
          // Android 键盘弹起后操作
          this.isButtonShow = false


        } else {
          // Android 键盘收起后操作
          this.isButtonShow = true

        }
      }
    }


    //获取图片
    // fsubmit() {
    //   let type = this.$refs.inputer.files[0].type
    //   var size = this.$refs.inputer.files[0].size / 1024;
    //   if (type == 'image/jpeg' || type == 'image/jpg' || type == 'image/png') {

    //     if (size > 5000) {
    //       Toast(this.$t('slide1.sizemax'))
    //     }
    //     else {
    //       this.image.push(this.$refs.inputer.files[0])
    //       this.imageName.push(this.$refs.inputer.files[0].name)
    //       this.len = this.image.length
    //     }
    //   }
    //   else {
    //     Toast(this.$t('slide1.imgerror'))

    //   }


    // },

    //上传图片
    // imgConfirm() {
    //   let that = this
    //   if (this.message == '') {
    //     Toast(this.$t('slide1.tips'))
    //   }
    //   else {
    //     var images = new FormData()
    //     for (let index = 0; index < this.image.length; index++) {
    //       images.append('image[' + index + ']', this.image[index])
    //     }
    //     $.ajax({
    //       url: that.baseURL + "/common/upload/base",
    //       type: "POST",
    //       data: images,
    //       processData: false,  // 告诉jQuery不要去处理发送的数据
    //       contentType: false,   // 告诉jQuery不要去设置Content-Type请求头
    //       success: function (response, status, xhr) {
    //         console.log(response.code);
    //         if (response.code == 0) {
    //           that.confirms()
    //         }
    //       }
    //     });




    //     // axios({
    //     //   url: that.baseURL + "/common/upload/path",
    //     //   method: 'post',
    //     //   data: images, //我们传formdata会占用整个传参对象
    //     //   headers: { "Content-Type": "	application/json" }
    //     // }).then(response => {
    //     //   if (response.data.code == 200) {
    //     //     Toast(this.$t('slide1.confirmsToasty'))
    //     //   }
    //     // })

    //   }
    // },

    //删除图片
    // deletes(detail) {
    //   return new Promise((resolve, reject) => {
    //     this.$dialog.confirm({
    //       message: this.$t('slide1.delMessage'),
    //       confirmButtonText: this.$t('slide1.determine'),
    //       cancelButtonText: this.$t('slide1.cancel'),

    //     }).then(() => {
    //       this.imageName.splice(detail.index, 1)
    //       this.image.splice(detail.index, 1)
    //       this.len = this.image.length
    //       this.$toast.success(this.$t('slide1.delTips'))
    //       // resolve()
    //     }).catch((error) => {
    //       // this.$toast.success('已取消')
    //       // reject(error)
    //     })
    //   })

    // },



    //预览图片
    // getObjectURL(file) {
    //   console.log(file);
    //   var url = null;
    //   if (window.createObjectURL != undefined) { // basic
    //     url = window.createObjectURL(file);
    //   } else if (window.URL != undefined) { // mozilla(firefox)
    //     url = window.URL.createObjectURL(file);
    //   } else if (window.webkitURL != undefined) { // webkit or chrome
    //     url = window.webkitURL.createObjectURL(file);
    //   }
    //   return url;
    // },


    //调取原生方法
    // getUserInformation(data) {
    //   this.userinfodata = data;
    //   let id = JSON.parse(data);
    //   axios.defaults.baseURL = id.serverUrl; //请求地址

    // },

  },

};

</script>

<style scoped>
html[lang="ar"] .text-right {
  text-align: right;
  direction: rtl;
}

html[lang="ar"] .message /deep/ .van-field__word-limit {
  text-align: left!important;
}

html[lang="ar"] ::-webkit-input-placeholder {
  text-align: right;
}

html[lang="ar"] .message /deep/ .van-field__control {
  text-align: right;
  direction: rtl;
}
html[lang="ar"] .van-uploader /deep/ .van-uploader__preview-delete{
  left: 0.1rem;
  right: auto;
}

html[lang="ar"] input {
  text-align: right;
}

html[lang="ar"] .dian {
  margin-left: 0.07rem;
  margin-right: 0;
}

/* input{
    font-size: 0.1rem;
} */
.dian {
  width: 0.07rem;
  height: 0.07rem;
  background: #5DC48A;
  border-radius: 50%;
  margin-right: 0.07rem;
}

.Description {
  font-size: 0.28rem;
  font-weight: 600;
  color: #000000;
  line-height: 0.3rem;
  font-family: 'Montserrat'!important;
}

.message {
  background: transparent;
  border-radius: 0.2rem;
  /* font-family: Regulars;
  font-weight: 400; */
  border: 1px solid #000;
  font-size: 0.24rem;
  font-family: 'Montserrat';
  font-weight: 400;
}

/deep/ .van-field__word-limit{
  color: #b0b6b6 !important;
  font-size: 0.24rem;
  font-family: 'Montserrat';
  font-weight: 500;
}
/deep/ .van-uploader__upload{
  width: 2.24rem;
  height: 2.24rem;
  border: 1px solid #000;
  border-radius: 0.2rem;
  background-color: transparent;
}
/deep/ .van-uploader__upload-icon{
  color: transparent;
  width: 0.24rem;
  height: 0.24rem;
  background: url('../assets/addIcon.png') no-repeat;
  background-size: 0.24rem 0.24rem;
}
/deep/ .van-uploader__preview-delete{
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  top: 0.1rem;
  right: 0.1rem;
  background: url('../assets/deleteIcon.png') no-repeat;
  background-size: 0.36rem 0.36rem;
}
/deep/ .van-uploader__preview-delete-icon{
  width: 100%;
  height: 100%;
  font-size: 24px;
  position: absolute;
  /* top: -4px;
  left: -4px; */
  left: 60%;
    top: 35%;
  transform: translate(-50%,-50%);
  display: none;
}
/deep/ .van-icon-cross::before{
  /* position: absolute;
  top: 0.06rem;
    left: 0.06rem; */
  /* transform: translate(-50%,-50%); */
  font-size: 18px;
}
.upload {
  width: 1.52rem;
  height: 1.52rem;
  background: #f6f7fb;
  border: 0;
  border-radius: 0.135rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
}

.upload .van-button__icon {
  color: #999999 !important;
}

/deep/ .van-uploader__preview-image {
  width: 2.24rem;
  height: 2.24rem;
  border-radius: 0.2rem;
}

.Confirm {
  background: #8131E7;
  opacity: 0.4;
  border-radius: 0.28rem;
  font-size: 0.28rem;
  font-family: 'PingFang SC';
  font-weight: 400;
  color: #ffffff;
  line-height: 0.92rem;
  /* padding: 0.27rem 0; */
  text-align: center;
  position: fixed;
  bottom: 0.61rem;
  margin: auto;
  left: 0.27rem;
  right: 0.27rem;
  width: 4.22rem;
  height: 0.92rem;
}

.van-loading {
  height: 15px;
}

/* .Confirmbtn {
  font-size: 0.254rem;
  font-family: Regular;
  font-weight: 500;
  color: #ffffff;
  padding: 0.288rem 0;
  background: #5dc48a;
  opacity: 0.4;
  border-radius: 45px;
  text-align: center;
  position: fixed;
  bottom: 0.6rem;
  margin: 0 16px;
  left: 0;
  right: 0;
} */

.uploader__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}

.uploader__preview {
  position: relative;
  margin: 0 8px 8px 0;
  cursor: pointer;
}

.uploader__preview-image {
  width: 1.52rem;
  height: 1.52rem;
  border-radius: 0.135rem;
}

.image__loading {
  display: block;
  width: 100%;
  height: 100%;
}

.uploader__preview-delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 0 0 0 12px;
}
.confirmBox{
  width: 100%;
  height: 3.54rem;
  background: url('../assets/bottomBg.png') no-repeat;
  background-size: 100% 3.54rem;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>